import {
  DolExploitationType,
  DolType,
  GenderType,
  MemberInformationCollege,
} from "../../domain/api"
import { WorkTypeOption } from "./Audiovisual/models"

export const REPERTORIES = [
  {
    label: "Audiovisuel",
    value: MemberInformationCollege.AUDIOVISUAL,
  },
  {
    label: "Images fixes",
    value: MemberInformationCollege.STILL_IMAGE,
  },
  {
    label: "Sonore",
    value: MemberInformationCollege.RADIO,
  },
  {
    label: "Écrit (Livre/Presse)",
    value: MemberInformationCollege.WRITTEN,
  },
]

export const WORK_TYPES: Array<WorkTypeOption> = [
  {
    repertory: MemberInformationCollege.AUDIOVISUAL,
    label: "Audiovisuelle",
    value: DolType.AUDIOVISUAL,
    urlRedirection: "/audiovisuelle/loeuvre",
    alert:
      "Toute œuvre doit être déclarée dans les trois mois suivant la première diffusion (française ou étrangère)",
  },
  {
    repertory: MemberInformationCollege.AUDIOVISUAL,
    label: "Traduction audiovisuelle",
    value: DolType.TRANSLAT_AUDIOVISUAL,
    urlRedirection: "/traduction-audiovisuelle/loeuvre",
    alert:
      "Toute œuvre doit être déclarée dans les trois mois suivant la diffusion",
  },
  {
    repertory: MemberInformationCollege.AUDIOVISUAL,
    label: "Vidéo Youtube",
    value: DolType.VIDEO_YOU_TUBE,
    urlRedirection: "/vidéo-YouTube/loeuvre",
    alert:
      "Pour Youtube les œuvres doivent être déclarées dans le mois suivant leur publication",
  },
  {
    repertory: MemberInformationCollege.RADIO,
    label: "Sonore (radio, podcast...)",
    value: DolType.RADIO,
    urlRedirection: "/radiophonique/loeuvre",
    alert:
      "Toute œuvre doit être déclarée dans les trois mois suivant la diffusion",
  },
  {
    repertory: MemberInformationCollege.WRITTEN,
    label: "Livre",
    value: DolType.WRITTEN_BOOK,
    urlRedirection: "/radiophonique/loeuvre",
    alert:
      "Merci de déclarer votre livre (publication en langue française) édité sous format papier (et/ou) numérique si possible dans un délai de trois mois après publication.",
  },
  {
    repertory: MemberInformationCollege.WRITTEN,
    label: "Presse",
    value: DolType.WRITTEN_PRESS,
    urlRedirection: "/radiophonique/loeuvre",
    alert:
      "Merci de déclarer régulièrement vos articles (publication en langue française) si possible dans un délai de trois mois après publication.",
  },
  {
    repertory: MemberInformationCollege.STILL_IMAGE,
    label: "Images fixes",
    value: DolType.STILL_IMAGE,
    urlRedirection: "/textuelle/loeuvre",
    alert: "Merci de déclarer vos œuvres dans des délais raisonnables",
  },
]

export const audiovisualSteps = [
  { index: -1, isChild: true },
  { index: 0, label: "Œuvre", isChild: false },
  { index: 1, isChild: true },
  { index: 2, label: "Inserts", isChild: false },
  { index: 3, isChild: true },
  { index: 4, isChild: true },
  { index: 5, label: "Producteurs", isChild: false },
  { index: 6, isChild: true },
  { index: 7, label: "Confirmation", isChild: false },
]

export const writtenSteps = (dolType: DolType) => [
  { index: -1, isChild: true },
  {
    index: 0,
    label: dolType === DolType.WRITTEN_PRESS ? "Article(s)" : "Œuvre",
    isChild: false,
  },
  {
    index: 1,
    label: "Récapitulatif et validation",
    isChild: false,
    hideLabelForMobile: true,
  },
  { index: 2, label: "Confirmation", isChild: false },
]

export const SPECIFICITY_OF_YOUR_WORK: {
  label: string
  value: string
}[] = [
  {
    label: "Docu-Fiction",
    value: "1",
  },
  {
    label: "Génériques et habillage",
    value: "2",
  },
  {
    label: "Œuvre institutionnelle",
    value: "3",
  },
]

export const EXPLOITATION_TYPE = [
  {
    label: "Edition (papier ou numérique)",
    value: DolExploitationType.BOOK,
  },
  {
    label: "Presse (papier ou numérique)",
    value: DolExploitationType.PRESS,
  },
  {
    label: "Télévisuelle",
    value: DolExploitationType.TELEVISUAL,
  },
]

export const GENDER_TYPE = [
  {
    label: "Dessin",
    value: GenderType.DRAWING,
  },
  {
    label: "Infographie",
    value: GenderType.INFOGRAPHICS,
  },
  {
    label: "Autre",
    value: GenderType.OTHER,
  },
  {
    label: "Photographie",
    value: GenderType.PHOTOGRAPHY,
  },
]
