/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ApplicationType {
    AUDIOVISUAL_PRIZE = 'AudiovisualPrize',
    AUDIOVISUAL_STAR = 'AudiovisualStar',
    BOOK_PRIZE = 'BookPrize',
    CULTURAL_EVENT = 'CulturalEvent',
    CULTURAL_STRUCTURE = 'CulturalStructure',
    DOCUMENTARY_DREAM_DRAFT = 'DocumentaryDreamDraft',
    EMERGENCES_PRIZE = 'EmergencesPrize',
    IDF_ACADEMY = 'IdfAcademy',
    IMPACT_DREAM_DRAFT = 'ImpactDreamDraft',
    INSTITUTIONAL_WORK_PRIZE = 'InstitutionalWorkPrize',
    JOURNALISM_DREAM_DRAFT = 'JournalismDreamDraft',
    LITERARY_DREAM_DRAFT = 'LiteraryDreamDraft',
    PHOTOGRAPHY_AND_DRAWING_DREAM_DRAFT = 'PhotographyAndDrawingDreamDraft',
    RADIO_AND_PODCAST_DREAM_DRAFT = 'RadioAndPodcastDreamDraft',
    ROGER_PIC_PRIZE = 'RogerPicPrize',
    WRITINGS_AND_EMERGING_FORMS_DREAM_DRAFT = 'WritingsAndEmergingFormsDreamDraft',
    WRITTEN_PRESS_PRIZE = 'WrittenPressPrize',
}
