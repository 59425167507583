import { WorkStatus } from "../domain/api"
import { ScamStatus } from "../pages/Works/models"

interface StringValueByScumStatus {
  scumStatuses: ScamStatus[]
  value: string
}

interface ActionsValueByScumStatus {
  scumStatuses: ScamStatus[]
  value: ActionProps[]
}

export interface StatusProps {
  value: WorkStatus
  defaultLabel: string
  labels?: StringValueByScumStatus[]
  order: number
  defaultColor: string
  colors?: StringValueByScumStatus[]
  defaultActions: ActionProps[]
  actions?: ActionsValueByScumStatus[]
  tooltips?: StringValueByScumStatus[]
  hasComplementActionWithCapability?: boolean
}

export interface ActionProps {
  label: string
  value: ActionsEnum
  url: string
}

export interface MenuModel {
  numero?: string
  label?: string
  path?: string
  opened?: boolean
  hasSubmenu?: boolean
  showForAllMembers: boolean
  showIsAnHeir: boolean
  showIsBe: boolean
  showCurios: boolean
  showOthersThanBe: boolean
  hiddenMenu?: boolean
  subMenus?: Array<MenuModel>
}

export enum ActionsEnum {
  CHECKOUT_DETAILS = "CheckOutDetails",
  EDIT = "Edit",
  DOWNLOAD_APPLICATION = "downloadApplication",
  CREATE_DOL = "CreateDOL",
  CREATE_LIST = "CreateList",
  RENEW = "Renew",
  MODIFICATION = "Modification",
  MODIFICATION_BO_MONITORING = "ModificationBoMonitoring",
  VIEW = "View",
  REMOVE = "Remove",
  ABANDONE = "Abandone",
}

export enum CountryEuroCode {
  AUT = "AT",
  BEL = "BE",
  BGR = "BG",
  HRV = "HR",
  CYP = "CY",
  CZE = "CZ",
  DNK = "DK",
  EST = "EE",
  FIN = "FI",
  FRA = "FR",
  DEU = "DE",
  GRC = "GR",
  HUN = "HU",
  IRL = "IE",
  ITA = "IT",
  LVA = "LV",
  LTU = "LT",
  LUX = "LU",
  MLT = "MT",
  NLD = "NL",
  POL = "PL",
  PRT = "PT",
  ROU = "RO",
  SVK = "SK",
  SVN = "SI",
  ESP = "ES",
  SWE = "SE",
}
