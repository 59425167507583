/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js"
import { ReactPlugin } from "@microsoft/applicationinsights-react-js"
import {
  ApplicationInsights,
  SeverityLevel,
} from "@microsoft/applicationinsights-web"
import { LEVEL } from "@rollbar/react"
import Rollbar, { Level } from "rollbar"

import { LOCAL_STORAGE_USER } from "../constants"
import {
  ClientDiagnosticConfigurationFrontDto,
  ConfigurationService,
  OpenAPI,
} from "../domain/api"
import { UserProfilFrontDtoExtended } from "../models"
import { LocalStorage } from "./LocalStorage"

let diagnosticConfiguration: ClientDiagnosticConfigurationFrontDto | null = null

const logger = {
  init: (isAdminApp: boolean) => init(isAdminApp),
  setUserContext: (
    userId: number | undefined,
    email: string | undefined,
    firstName: string | undefined,
    lastName: string | undefined,
  ) => setUserContext(userId, email, firstName, lastName),
  clearUserContext: () => clearUserContext(),
  getReactPlugin: () => reactPlugin,
  getRollbar: () => rollbar,
  getRollbarMinimalLogLEVEL: () => getRollbarMinimalLogLEVEL(),
  debug: (message?: any, ...optionalArguments: any[]) => {
    if (isConsoleLogEnabled) console.debug(message, ...optionalArguments)
    appInsights?.trackTrace({
      message: message,
      severityLevel: SeverityLevel.Verbose,
    })
    rollbar.debug(message, ...optionalArguments)
  },
  info: (message?: any, ...optionalArguments: any[]) => {
    if (isConsoleLogEnabled) console.info(message, ...optionalArguments)
    appInsights?.trackTrace({
      message: message,
      severityLevel: SeverityLevel.Information,
    })
    rollbar.info(message, ...optionalArguments)
  },
  warn: (message?: any, ...optionalArguments: any[]) => {
    if (isConsoleLogEnabled) console.warn(message, ...optionalArguments)
    appInsights?.trackTrace({
      message: message,
      severityLevel: SeverityLevel.Warning,
    })
    rollbar.warn(message, ...optionalArguments)
  },
  error: (message?: any, ...optionalArguments: any[]) => {
    if (isConsoleLogEnabled) console.error(message, optionalArguments)
    appInsights?.trackException({
      exception: message,
      severityLevel: SeverityLevel.Error,
    })
    rollbar.error(message, ...optionalArguments)
  },
  critical: (message?: any, ...optionalArguments: any[]) => {
    if (isConsoleLogEnabled) console.error(message, ...optionalArguments)
    appInsights?.trackException({
      exception: message,
      severityLevel: SeverityLevel.Critical,
    })
    rollbar.critical(message, ...optionalArguments)
  },
}

const init = (isAdminApp: boolean): void => {
  OpenAPI.BASE = process.env.REACT_APP_API_ROOT as string
  OpenAPI.WITH_CREDENTIALS = true
  ConfigurationService.getConfigurationConfiguration()
    .then((clientDiagnosticConfiguration) => {
      diagnosticConfiguration = clientDiagnosticConfiguration
      // Configure AppInsights with settings from api call
      if (isAppInsightsConfigurationValidAndEnabled()) {
        appInsights = new ApplicationInsights({
          config: {
            connectionString:
              diagnosticConfiguration.applicationInsights.connectionString,
            enableAutoRouteTracking: true,
            autoTrackPageVisitTime: true,
            enableCorsCorrelation: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            extensions: [reactPlugin, clickPluginInstance],
            extensionConfig: {
              [clickPluginInstance.identifier]: clickPluginConfig,
            },
          },
        })
        appInsights.loadAppInsights()
        // Add a telemetry initializer to filter telemetry types
        appInsights.addTelemetryInitializer((envelope) => {
          if (
            envelope.baseType &&
            telemetryTypes.includes(envelope.baseType) &&
            (envelope.baseType !== TelemetryType.MessageData.toString() ||
              (envelope.baseData &&
                Number(envelope.baseData.severityLevel) >=
                  Number(
                    LogLevel[
                      diagnosticConfiguration!.applicationInsights
                        .minimalLogLevel
                    ],
                  )))
          ) {
            return true
          }
          return false
        })
      }

      // Configure Rollbar with settings from api call
      if (isRollbarConfigurationValidAndEnabled()) {
        rollbar.configure({
          enabled: diagnosticConfiguration.rollbar.enabled,
          reportLevel: mapRollbarLogLevelFromLogLevel(
            diagnosticConfiguration.rollbar.minimalLogLevel.toString(),
          ),
          environment: diagnosticConfiguration.labelFilter,
        })
      }

      // Set user context when user already is already logged in
      if (!isAdminApp) {
        const userContext: UserProfilFrontDtoExtended | null = JSON.parse(
          LocalStorage.GetItem(LOCAL_STORAGE_USER)!,
        )
        logger.setUserContext(
          userContext?.user?.user?.userId,
          userContext?.user?.user?.email,
          userContext?.user?.user?.firstName,
          userContext?.user?.user?.lastName,
        )
      }
    })
    .catch((error) => {
      console.log(error)
    })
}

const setUserContext = (
  userId: number | undefined,
  email: string | undefined,
  firstName: string | undefined,
  lastName: string | undefined,
): void => {
  if (userId) {
    if (isAppInsightsConfigurationValidAndEnabled() && appInsights) {
      appInsights.setAuthenticatedUserContext(
        userId.toString(),
        undefined,
        true,
      )
    }
    if (isRollbarConfigurationValidAndEnabled()) {
      rollbar.configure({
        payload: {
          person: {
            id: userId,
            email: email,
            username: firstName + "-" + lastName,
          },
        },
      })
    }
  } else {
    clearUserContext()
  }
}

const clearUserContext = (): void => {
  if (isAppInsightsConfigurationValidAndEnabled() && appInsights) {
    appInsights.clearAuthenticatedUserContext()
  }
  if (isRollbarConfigurationValidAndEnabled()) {
    rollbar.configure({
      payload: {},
    })
  }
}

/** *****AppInsights*******/
const isAppInsightsConfigurationValid = (): boolean =>
  !!diagnosticConfiguration?.applicationInsights &&
  !!diagnosticConfiguration?.applicationInsights?.connectionString
const isAppInsightsConfigurationValidAndEnabled = (): boolean =>
  isAppInsightsConfigurationValid() &&
  diagnosticConfiguration!.applicationInsights.enabled

const reactPlugin = new ReactPlugin()
const clickPluginInstance = new ClickAnalyticsPlugin()
const clickPluginConfig = {
  autoCapture: true,
  dataTags: {
    useDefaultContentNameOrId: true,
    captureAllMetaDataContent: true,
  },
  urlCollectHash: true,
  urlCollectQuery: true,
}
let appInsights: ApplicationInsights | null = null

enum LogLevel {
  Debug = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
  Fatal = 4,
}
enum TelemetryType {
  EventData = "EventData",
  ExceptionData = "ExceptionData",
  MessageData = "MessageData",
  PageViewData = "PageviewData",
  RemoteDependencyData = "RemoteDependencyData",
  RequestData = "RequestData",
  MetricData = "MetricData",
  PageviewPerformanceData = "PageviewPerformanceData",
}
const telemetryTypes: string[] = [
  TelemetryType.EventData,
  TelemetryType.ExceptionData,
  TelemetryType.MessageData,
  TelemetryType.PageViewData,
  TelemetryType.RemoteDependencyData,
  TelemetryType.RequestData,
  TelemetryType.MetricData,
  TelemetryType.PageviewPerformanceData,
]
/** *****AppInsights*******/

/** *****Rollbar*******/
const isRollbarClientAccessTokenValid =
  !!process.env.REACT_APP_ROLLBAR_CLIENT_ACCESS_TOKEN
const isRollbarConfigurationValid = (): boolean =>
  isRollbarClientAccessTokenValid &&
  !!diagnosticConfiguration?.rollbar &&
  !!diagnosticConfiguration?.rollbar?.minimalLogLevel
const isRollbarConfigurationValidAndEnabled = (): boolean =>
  isRollbarConfigurationValid() && diagnosticConfiguration!.rollbar.enabled

const isLevel = (level: string | undefined): boolean =>
  !!level && ["debug", "info", "warning", "error", "critical"].includes(level)
const getRollbarMinimalLogLevel = (): Level =>
  isLevel(process.env.REACT_APP_ROLLBAR_MINIMAL_LOG_LEVEL)
    ? (process.env.REACT_APP_ROLLBAR_MINIMAL_LOG_LEVEL as Level)
    : "error"

const isLEVEL = (level: string | undefined): boolean =>
  !!level && ["debug", "info", "warn", "error", "critical"].includes(level)
const getRollbarMinimalLogLEVEL = (): LEVEL =>
  isLEVEL(process.env.REACT_APP_ROLLBAR_MINIMAL_LOG_LEVEL)
    ? (process.env.REACT_APP_ROLLBAR_MINIMAL_LOG_LEVEL as LEVEL)
    : "error"

// Configure Rollbar with environment variables, waiting for settings from api call
const rollbar = new Rollbar({
  accessToken: isRollbarClientAccessTokenValid
    ? process.env.REACT_APP_ROLLBAR_CLIENT_ACCESS_TOKEN
    : "POST_CLIENT_ITEM_ACCESS_TOKEN",
  enabled:
    isRollbarClientAccessTokenValid &&
    process.env.REACT_APP_ROLLBAR_ENABLED?.toLowerCase() === "true",
  reportLevel: getRollbarMinimalLogLevel(),
  environment: process.env.REACT_APP_LABEL_FILTER,
  captureUncaught: true,
  captureUnhandledRejections: true,
})

const mapRollbarLogLevelFromLogLevel = (logLevel: string): Level => {
  switch (logLevel) {
    case "Debug":
      return "debug"
    case "Information":
      return "info"
    case "Warning":
      return "warning"
    case "Error":
      return "error"
    case "Fatal":
      return "critical"
    default:
      throw new Error("Invalid log level")
  }
}
/** ******Rollbar*******/

/** ******Console*******/
const isConsoleLogEnabled = process.env.REACT_APP_ENVIRONMENT !== "prod"
/** ******Console*******/

export default logger
