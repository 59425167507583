/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PbeTokenState {
    EXPIRED = 'Expired',
    FINALIZED = 'Finalized',
    RESERVED = 'Reserved',
    VALID = 'Valid',
}
