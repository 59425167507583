/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PbeDocumentStep {
    FOLLOW_UP_JURY_ADMINISTRATIVE_DOCUMENT = 'FollowUpJuryAdministrativeDocument',
    FOLLOW_UP_JURY_AUTHOR = 'FollowUpJuryAuthor',
    FOLLOW_UP_JURY_PROJECT = 'FollowUpJuryProject',
    FOLLOW_UP_PRESELECTION = 'FollowUpPreselection',
    FOLLOW_UP_RECEIVED_DOSSIER = 'FollowUpReceivedDossier',
    FORM_AUTHOR = 'FormAuthor',
    FORM_BEFORE_STARTING = 'FormBeforeStarting',
    FORM_ELIGIBILITY = 'FormEligibility',
    FORM_PROJECT = 'FormProject',
    FORM_REGISTRATION = 'FormRegistration',
    FORM_VALIDATION = 'FormValidation',
}
