import moment from "moment"

import { GENDERCODELABELS } from "../../constants"
import {
  DolType,
  MemberInformationCollege,
  WorkFrontDto,
} from "../../domain/api"
import { dateUtcToLocalFormatted } from "../../helpers/formatText"
import { SECTIONS } from "./models"

export const reEvaluateDate = (dateValue: string): string => {
  const minDate = new Date(1970, 0, 1)
  const parsedDate = dateValue ? new Date(dateValue) : minDate

  return parsedDate > minDate
    ? dateUtcToLocalFormatted(dateValue, "DD/MM/YYYY")
    : ""
}

export const reEvaluateTimeFromDate = (date: string) => {
  return moment(date).format("HH:mm")
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reEvaluateEditor = (row: any): string => {
  if (row.isAutoEdition === true) {
    return "Auto-édition"
  } else if (row.publisherPartnerName !== null) {
    return row.publisherPartnerName
  } else if (
    row.selfPublishingName === null &&
    row.publisherPartnerName === null
  ) {
    return ""
  }
  return ""
}

export const getSectionByType = (type: string) =>
  SECTIONS.find((item) => item.type === type)

export const getSection = (
  repertory?: MemberInformationCollege,
  dolType?: DolType,
) =>
  SECTIONS.find(
    (item) =>
      repertory === item.repertory &&
      (item?.dolType ? dolType === item.dolType : true),
  )

export const getIsPaperAsString = (val: boolean | undefined) =>
  typeof val !== "undefined" ? (val ? "PAPIER" : "NUMERIQUE") : ""

export const getWorkSubfieldAsArray = (work: WorkFrontDto, field?: string) => {
  const value = work[field as keyof typeof work]
  return Array.isArray(value) ? value : [{}]
}

export const getWorkGenre = (work: WorkFrontDto) =>
  work.dolType === DolType.WRITTEN_BOOK
    ? work.workDetailBooks
      ? work.workDetailBooks[0].genre
      : "N/C"
    : GENDERCODELABELS.find((x) => x.value === work.validateRanking)?.label ??
      "N/C"
