/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApplicationFrontDto } from '../models/ApplicationFrontDto';
import type { ApplicationUpdateRequestDto } from '../models/ApplicationUpdateRequestDto';
import type { PbeDraftApplicationFrontDto } from '../models/PbeDraftApplicationFrontDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PbeApplicationService {

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static completePbeApplicationPbeApplication(
requestBody?: PbeDraftApplicationFrontDto,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/pbeapplication/completepbeapplication',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static deletePbeDraftApplicationPbeApplication(
requestBody?: PbeDraftApplicationFrontDto,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/pbeapplication/deletepbedraftapplication',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns ApplicationFrontDto Success
     * @throws ApiError
     */
    public static getAllUserPbeApplicationsPbeApplication(): CancelablePromise<Array<ApplicationFrontDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pbeapplication/getalluserpbeapplications',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param pbeApplicationId 
     * @returns PbeDraftApplicationFrontDto Success
     * @throws ApiError
     */
    public static getPbeDraftApplicationPbeApplication(
pbeApplicationId: string,
): CancelablePromise<PbeDraftApplicationFrontDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pbeapplication/getpbedraftapplication/{pbeApplicationId}',
            path: {
                'pbeApplicationId': pbeApplicationId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param sessionId 
     * @returns PbeDraftApplicationFrontDto Success
     * @throws ApiError
     */
    public static getPbeDraftApplicationBySessionPbeApplication(
sessionId: number,
): CancelablePromise<PbeDraftApplicationFrontDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pbeapplication/getpbedraftapplicationbysession/{sessionId}',
            path: {
                'sessionId': sessionId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static savePbeDraftApplicationPbeApplication(
requestBody?: PbeDraftApplicationFrontDto,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/pbeapplication/savepbedraftapplication',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ApplicationFrontDto Success
     * @throws ApiError
     */
    public static updatePbeApplicationPbeApplication(
requestBody?: ApplicationUpdateRequestDto,
): CancelablePromise<ApplicationFrontDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/pbeapplication/updatepbeapplication',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server Error`,
            },
        });
    }

}
