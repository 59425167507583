/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PbeTokenFrontDto } from '../models/PbeTokenFrontDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PbeTokenService {

    /**
     * @param pbeSessionId 
     * @returns PbeTokenFrontDto Success
     * @throws ApiError
     */
    public static createUserTokenPbeToken(
pbeSessionId: number,
): CancelablePromise<PbeTokenFrontDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/pbetoken/createtoken/{pbeSessionId}',
            path: {
                'pbeSessionId': pbeSessionId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param tokenId 
     * @returns PbeTokenFrontDto Success
     * @throws ApiError
     */
    public static getPbeTokenByIdPbeToken(
tokenId: number,
): CancelablePromise<PbeTokenFrontDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pbetoken/{tokenId}',
            path: {
                'tokenId': tokenId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param sessionId 
     * @returns PbeTokenFrontDto Success
     * @throws ApiError
     */
    public static getPbeTokenBySessionIdPbeToken(
sessionId: number,
): CancelablePromise<PbeTokenFrontDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pbetoken/tokenbysession/{sessionId}',
            path: {
                'sessionId': sessionId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param pbeSessionId 
     * @returns PbeTokenFrontDto Success
     * @throws ApiError
     */
    public static invalidateUserTokenPbeToken(
pbeSessionId: number,
): CancelablePromise<PbeTokenFrontDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/pbetoken/invalidateusertoken/{pbeSessionId}',
            path: {
                'pbeSessionId': pbeSessionId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

}
