/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ApplicationCategory {
    ALBERT_LONDRES_PRIZE = 'AlbertLondresPrize',
    FINANCIAL_SUPPORT = 'FinancialSupport',
    GRANT = 'Grant',
    SCAM_PRIZE = 'ScamPrize',
    STAR = 'Star',
}
