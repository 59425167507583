/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ApplicationSubmitter {
    BROADCASTER = 'Broadcaster',
    CANDIDATE = 'Candidate',
    EDITOR = 'Editor',
    PARTNER_COLLABORATOR = 'PartnerCollaborator',
    PRODUCER = 'Producer',
    SCAM = 'Scam',
}
