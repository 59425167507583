/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ApplicationWorkClassification {
    ANIMATION = 'Animation',
    APPLICATION = 'Application',
    BIOGRAPHY = 'Biography',
    CINEMATIC_ESSAY = 'CinematicEssay',
    COMIC = 'Comic',
    COMMISSIONED_WORK = 'CommissionedWork',
    CORRESPONDANCE = 'Correspondance',
    DIGITAL_ARTWORK = 'DigitalArtwork',
    DIGITAL_WORK = 'DigitalWork',
    DOCUMENTARY = 'Documentary',
    DOCUMENTARY_CINEMATIC_RESEARCH = 'DocumentaryCinematicResearch',
    DOCUMENTARY_GAMES = 'DocumentaryGames',
    DOCUMENTARY_WORK_EXTENSION = 'DocumentaryWorkExtension',
    DRAWING = 'Drawing',
    ESSAY = 'Essay',
    EXPERIMENTAL_DOCUMENTARY_INSTALLATION = 'ExperimentalDocumentaryInstallation',
    FILM = 'Film',
    GENERATIVE_ART = 'GenerativeArt',
    GRAPHIC_NOVEL = 'GraphicNovel',
    INFOGRAPHICS = 'Infographics',
    INSTALLATION = 'Installation',
    INSTITUTIONAL_WORK = 'InstitutionalWork',
    INSTITUTIONAL_WORK_CREATION = 'InstitutionalWorkCreation',
    INTERACTIVE_DIGITAL_ARTWORK = 'InteractiveDigitalArtwork',
    INTERACTIVE_DIGITAL_BOOK = 'InteractiveDigitalBook',
    INVESTIGATIVE_REPORTING = 'InvestigativeReporting',
    LINEAR_OR_NONLINEAR_DIGITAL_ARTWORK = 'LinearOrNonlinearDigitalArtwork',
    NETWORKED_OR_NOMADIC_GENERATIVE_WORK = 'NetworkedOrNomadicGenerativeWork',
    NEWSPAPER = 'Newspaper',
    NON_FICTION_ROMANCE = 'NonFictionRomance',
    NOTEBOOK = 'Notebook',
    NOVEL = 'Novel',
    OTHER = 'Other',
    PHOTOGRAPHY = 'Photography',
    PHOTOJOURNALISM = 'Photojournalism',
    PHOTO_NOVEL = 'PhotoNovel',
    PHOTO_VIDEOGRAPHY = 'PhotoVideography',
    POETRY = 'Poetry',
    PRESS = 'Press',
    PRESS_REPORTING = 'PressReporting',
    RADIO = 'Radio',
    RADIO_REPORTING = 'RadioReporting',
    REPORTING = 'Reporting',
    RESEARCH_AND_DIGITAL_EXPERIMENTATION = 'ResearchAndDigitalExperimentation',
    SERIES = 'Series',
    SHORT_STORY = 'ShortStory',
    SINGLE_CREATIVE_DOCUMENTARY = 'SingleCreativeDocumentary',
    SINGLE_DOCUMENTARY = 'SingleDocumentary',
    SONOROUS_INSTALLATION = 'SonorousInstallation',
    STORYTELLING = 'Storytelling',
    SYNTHESIS = 'Synthesis',
    TELEVISUAL_REPORTING = 'TelevisualReporting',
    THREE_DANIMATION = 'ThreeDAnimation',
    TRANSMEDIA_WORK = 'TransmediaWork',
    VIDEO = 'Video',
    VIDEO_ARTEXPERIMENTAL_DOCUMENTARY = 'VideoArtexperimentalDocumentary',
    VIRTUAL_OR_AUGMENTED_REALITY = 'VirtualOrAugmentedReality',
    VIRTUAL_WORK = 'VirtualWork',
    WEB_DOCUMENTARY = 'WebDocumentary',
    WEB_PLATFORM = 'WebPlatform',
    WEB_REPORTING = 'WebReporting',
    WRITTEN = 'Written',
}
