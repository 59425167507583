/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContentFrontDto } from '../models/ContentFrontDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ContentService {

    /**
     * @param contentId 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteContentByIdContent(
contentId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/content/deletecontentbyid',
            query: {
                'contentId': contentId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param codes 
     * @returns ContentFrontDto Success
     * @throws ApiError
     */
    public static getContentByCodesContent(
codes?: Array<string>,
): CancelablePromise<Array<ContentFrontDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/content/getcontentbycodes',
            query: {
                'codes': codes,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param contentId 
     * @returns ContentFrontDto Success
     * @throws ApiError
     */
    public static getContentByIdContent(
contentId?: number,
): CancelablePromise<ContentFrontDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/content/getcontentbyid',
            query: {
                'contentId': contentId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

}
