/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientDiagnosticConfigurationFrontDto } from '../models/ClientDiagnosticConfigurationFrontDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConfigurationService {

    /**
     * @returns ClientDiagnosticConfigurationFrontDto Success
     * @throws ApiError
     */
    public static getConfigurationConfiguration(): CancelablePromise<ClientDiagnosticConfigurationFrontDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/configuration',
            errors: {
                500: `Server Error`,
            },
        });
    }

}
