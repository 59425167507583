import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid"

import { WorkStatus } from "../../../../domain/api"
import { classNames } from "../../../../helpers"
import { EXPLOITATION_TYPE, GENDER_TYPE } from "../../../DOL/constants"
// import { WORK_TYPES } from "../../../DOL/constants"
import { reEvaluateDate } from "../../helpers"
import styles from "../../Works.module.scss"
import { OverflowGridCellComponent } from "../OverflowGridCellComponent"
import { StatusButton } from "../StatusButton"
import { StatusText } from "../StatusText"
import { ColumnsProps } from "./models"

export const getImageColumns = (props: ColumnsProps): GridColDef[] => {
  const { tokenDnn, handleOpenWork, loadFile, handleRemoveDol, stateSnapshot } =
    props
  return [
    {
      field: "title",
      headerName: "Titre",
      // minWidth: 200,
      flex: 2,
      headerClassName: styles["container-table-header"],
      cellClassName: (params) =>
        classNames(
          styles["container-table-cell"],
          params.row.status !== WorkStatus.ON_GOIN_WORK
            ? styles["container-table-cellWritten"]
            : "",
        ) ?? "",
      sortable: true,
      filterable: true,
      hideable: false,
      renderCell: (params) => (
        <>
          <OverflowGridCellComponent
            className={styles.withOnClick}
            value={params.value}
            onClick={() => handleOpenWork && handleOpenWork(params.row)}
          />
          <OverflowGridCellComponent
            className={styles.withoutOnClick}
            value={params.value}
          />
        </>
      ),
    },
    {
      field: "declarationDate",
      headerName: "Déclarée le",
      headerClassName: styles["container-table-header"],
      maxWidth: 120,
      flex: 1,
      cellClassName: styles["container-table-cell"],
      sortable: true,
      filterable: true,
      valueFormatter: (params: GridValueFormatterParams) =>
        reEvaluateDate(params.value),
    },
    {
      field: "genderType",
      headerName: "Genre",
      headerClassName: styles["container-table-header"],
      maxWidth: 110,
      flex: 1,
      cellClassName: styles["container-table-cell"],
      sortable: true,
      filterable: true,
      renderCell: (params) => (
        <OverflowGridCellComponent
          value={
            GENDER_TYPE.filter((s) => s.value === params.value)?.[0]?.label
          }
        />
      ),
    },
    {
      field: "exploitationType",
      headerName: "Type d'exploitation",
      headerClassName: styles["container-table-header"],
      flex: 1,
      minWidth: 150,
      cellClassName: styles["container-table-cell"],
      sortable: true,
      filterable: true,
      renderCell: (params) => (
        <OverflowGridCellComponent
          value={
            EXPLOITATION_TYPE.filter((s) => s.value === params.value)?.[0]
              ?.label
          }
        />
      ),
    },
    {
      field: "exploitation",
      headerName: "Exploitant ou éditeur",
      headerClassName: styles["container-table-header"],
      // minWidth: 150,
      flex: 1,
      cellClassName: styles["container-table-cell"],
      sortable: true,
      filterable: true,
      renderCell: (params) => (
        <OverflowGridCellComponent value={params.value} />
      ),
    },
    {
      field: "quantity",
      headerName: "Nb d'images",
      headerClassName: styles["container-table-header"],
      maxWidth: 120,
      flex: 1,
      cellClassName: styles["container-table-cell"],
      sortable: true,
      filterable: true,
      renderCell: (params) => (
        <OverflowGridCellComponent value={params.value} />
      ),
    },
    {
      field: "broadcastDateEnd",
      headerName: "Date d'exploitation",
      headerClassName: styles["container-table-header"],
      // maxWidth: 120,
      flex: 1,
      cellClassName: styles["container-table-cell"],
      sortable: true,
      filterable: true,
      valueFormatter: (params: GridValueFormatterParams) =>
        reEvaluateDate(params.value),
    },
    {
      field: "status",
      headerName: "Statut",
      headerClassName: styles["container-table-header"],
      cellClassName: styles["container-table-cell"],
      // minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <StatusText
          status={params.row.status}
          scamStatus={params.row.scamStatus ?? ""}
          // state={params.row.state}
        />
      ),
      sortable: false,
      filterable: false,
    },
    {
      field: "statusSelect",
      headerName: "",
      maxWidth: 120,
      flex: 1,
      headerClassName: styles["container-table-header"],
      cellClassName: styles["container-table-cell"],
      editable: false,
      renderCell: (params: GridRenderCellParams) => (
        <StatusButton
          row={params.row}
          tokenDnn={tokenDnn}
          loadFile={loadFile}
          handleRemoveDol={handleRemoveDol}
          stateSnapshot={stateSnapshot}
        />
      ),
      sortable: false,
      filterable: false,
    },
  ]
}
