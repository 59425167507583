/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ApplicationDecisionType {
    JURY_MENTION = 'JuryMention',
    LABELED = 'Labeled',
    LABELING_POSTPONEMENT = 'LabelingPostponement',
    LABELING_REFUSAL = 'LabelingRefusal',
    LABEL_POSTPONEMENT_PRESENTED = 'LabelPostponementPresented',
    NOTICED = 'Noticed',
    NOT_SELECTED = 'NotSelected',
    POSTPONEMENT_OR_REVISION = 'PostponementOrRevision',
    POSTPONEMENT_OR_REVISION_PRESENTED = 'PostponementOrRevisionPresented',
    PRE_SELECTED = 'PreSelected',
    PRESENTED = 'Presented',
    REPERAGES_WINNER = 'ReperagesWinner',
    SPONSORED = 'Sponsored',
    WINNER = 'Winner',
}
