/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PbeFormFrontDto } from '../models/PbeFormFrontDto';
import type { PbeSessionFrontDto } from '../models/PbeSessionFrontDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PbeBoService {

    /**
     * @returns PbeFormFrontDto Success
     * @throws ApiError
     */
    public static getFormsByUserScopePbeBo(): CancelablePromise<Array<PbeFormFrontDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pbebo/formsbyuserscope',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param formId 
     * @returns PbeFormFrontDto Success
     * @throws ApiError
     */
    public static getPbeFormByIdPbeBo(
formId: number,
): CancelablePromise<PbeFormFrontDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pbebo/{formId}',
            path: {
                'formId': formId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param pbeSessionId 
     * @param pbeFormType 
     * @returns any Success
     * @throws ApiError
     */
    public static publishPbeSessionPbeBo(
pbeSessionId: number,
pbeFormType: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/pbebo/publishpbesession/{pbeFormType}/{pbeSessionId}',
            path: {
                'pbeSessionId': pbeSessionId,
                'pbeFormType': pbeFormType,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param pbeFormType 
     * @param requestBody 
     * @returns PbeSessionFrontDto Success
     * @throws ApiError
     */
    public static savePbeSessionPbeBo(
pbeFormType: string,
requestBody?: PbeSessionFrontDto,
): CancelablePromise<PbeSessionFrontDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/pbebo/savepbesession/{pbeFormType}',
            path: {
                'pbeFormType': pbeFormType,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server Error`,
            },
        });
    }

}
