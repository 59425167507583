import { SVGAttributes, VFC } from "react"

export const Info: VFC<SVGAttributes<SVGElement>> = (props) => (
  <svg
    data-token-name="Info"
    width="1em"
    height="1em"
    fill="currentColor"
    {...props}
    viewBox="0 0 20 20"
  >
    <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z" />
  </svg>
)
