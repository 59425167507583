/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserFrontDto } from '../models/UserFrontDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * @param beneficiaryId 
     * @returns UserFrontDto Success
     * @throws ApiError
     */
    public static getUserUsers(
beneficiaryId?: number,
): CancelablePromise<UserFrontDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users',
            query: {
                'beneficiaryId': beneficiaryId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

}
