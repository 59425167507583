/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdUserFrontDto } from '../models/AdUserFrontDto';
import type { ConnectedSpaceUserRolesFrontDto } from '../models/ConnectedSpaceUserRolesFrontDto';
import type { ContentFrontDto } from '../models/ContentFrontDto';
import type { Reference } from '../models/Reference';
import type { SendDolToCrmFrontDto } from '../models/SendDolToCrmFrontDto';
import type { SendUserPalToCrmFrontDto } from '../models/SendUserPalToCrmFrontDto';
import type { UpdateElectreByEanFrontDto } from '../models/UpdateElectreByEanFrontDto';
import type { UserRoleFrontDto } from '../models/UserRoleFrontDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdminService {

    /**
     * @param requestBody 
     * @returns UserRoleFrontDto Success
     * @throws ApiError
     */
    public static addUserRoleAdmin(
requestBody?: UserRoleFrontDto,
): CancelablePromise<UserRoleFrontDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/adduserroleasync',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserRoleFrontDto Success
     * @throws ApiError
     */
    public static getAllUsersRolesAdmin(): CancelablePromise<Array<UserRoleFrontDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/allusersroles',
        });
    }

    /**
     * @returns ConnectedSpaceUserRolesFrontDto Success
     * @throws ApiError
     */
    public static getCurrentUserRolesAdmin(): CancelablePromise<ConnectedSpaceUserRolesFrontDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/currentuserroles',
        });
    }

    /**
     * @returns Reference Success
     * @throws ApiError
     */
    public static getRolesAdmin(): CancelablePromise<Array<Reference>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/roles',
        });
    }

    /**
     * @param requestBody 
     * @returns UserRoleFrontDto Success
     * @throws ApiError
     */
    public static removeUserRoleAdmin(
requestBody?: UserRoleFrontDto,
): CancelablePromise<UserRoleFrontDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/removeuserrole',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param searchTerm 
     * @returns AdUserFrontDto Success
     * @throws ApiError
     */
    public static searchAdUsersAdmin(
searchTerm?: string,
): CancelablePromise<Array<AdUserFrontDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/searchadusers',
            query: {
                'searchTerm': searchTerm,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns UserRoleFrontDto Success
     * @throws ApiError
     */
    public static updateUserRoleAdmin(
requestBody?: UserRoleFrontDto,
): CancelablePromise<UserRoleFrontDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/updateuserrole',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static clearCacheAdmin(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/clearcache',
        });
    }

    /**
     * @param cacheKey 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteCahceEntryAdmin(
cacheKey: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/cachekey/{cacheKey}',
            path: {
                'cacheKey': cacheKey,
            },
        });
    }

    /**
     * @param library 
     * @param documentType 
     * @param fileName 
     * @returns boolean Success
     * @throws ApiError
     */
    public static deleteSharepointDocumentAdmin(
library?: string,
documentType?: string,
fileName?: string,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/deletesharepointdocument',
            query: {
                'library': library,
                'documentType': documentType,
                'fileName': fileName,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @returns string Success
     * @throws ApiError
     */
    public static getCacheKeysAdmin(): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/cachekeys',
        });
    }

    /**
     * @param types 
     * @returns ContentFrontDto Success
     * @throws ApiError
     */
    public static getContentByTypesAdmin(
types?: Array<string>,
): CancelablePromise<Array<ContentFrontDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/getcontentbytypes',
            query: {
                'types': types,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static refreshCacheAdmin(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/refreshcache',
        });
    }

    /**
     * @param requestBody 
     * @returns ContentFrontDto Success
     * @throws ApiError
     */
    public static saveContentAdmin(
requestBody?: ContentFrontDto,
): CancelablePromise<ContentFrontDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/savecontent',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns SendUserPalToCrmFrontDto Success
     * @throws ApiError
     */
    public static sendUserPalsToCrmAdmin(
requestBody?: SendUserPalToCrmFrontDto,
): CancelablePromise<SendUserPalToCrmFrontDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/senduserpalstocrm',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param userId 
     * @returns any Success
     * @throws ApiError
     */
    public static sendUserPalToCrmAdmin(
userId: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/senduserpaltocrm/{userId}',
            path: {
                'userId': userId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns SendDolToCrmFrontDto Success
     * @throws ApiError
     */
    public static sendWrittenWorksToCrmByDolIdsAdmin(
requestBody?: SendDolToCrmFrontDto,
): CancelablePromise<SendDolToCrmFrontDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/sendwrittenworkstocrmbydolid',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns SendDolToCrmFrontDto Success
     * @throws ApiError
     */
    public static sendWrittenWorksToCrmByParentDolIdsAdmin(
requestBody?: SendDolToCrmFrontDto,
): CancelablePromise<SendDolToCrmFrontDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/sendwrittenworkstocrmbyparentdolid',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param dolId 
     * @returns any Success
     * @throws ApiError
     */
    public static sendWrittenWorkToCrmByDolIdAdmin(
dolId: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/sendwrittenworktocrmbydolid/{dolId}',
            path: {
                'dolId': dolId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param parentDolId 
     * @returns any Success
     * @throws ApiError
     */
    public static sendWrittenWorkToCrmByParentDolIdAdmin(
parentDolId: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/sendwrittenworktocrmbyparentdolid/{parentDolId}',
            path: {
                'parentDolId': parentDolId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns UpdateElectreByEanFrontDto Success
     * @throws ApiError
     */
    public static updateWrittenWorkElectreIdFromEanAdmin(
requestBody?: UpdateElectreByEanFrontDto,
): CancelablePromise<UpdateElectreByEanFrontDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/updatewrittenworkelectreidfromean',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param library 
     * @param documentType 
     * @param creationDate 
     * @param culture 
     * @param fileName 
     * @param fiscalYear 
     * @param number 
     * @param title 
     * @param formData 
     * @returns boolean Success
     * @throws ApiError
     */
    public static uploadSharepointDocumentAdmin(
library?: string,
documentType?: string,
creationDate?: string,
culture?: boolean,
fileName?: string,
fiscalYear?: number,
number?: number,
title?: string,
formData?: {
file: Blob;
},
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/uploadsharepointdocument',
            query: {
                'library': library,
                'documentType': documentType,
                'CreationDate': creationDate,
                'Culture': culture,
                'FileName': fileName,
                'FiscalYear': fiscalYear,
                'Number': number,
                'Title': title,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                404: `Not Found`,
            },
        });
    }

}
