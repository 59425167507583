/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Role {
    ADMINISTRATOR = 'Administrator',
    COM = 'Com',
    CULTURE_BOURSE = 'CultureBourse',
    CULTURE_ETOILE = 'CultureEtoile',
    CULTURE_PRIX = 'CulturePrix',
    SUPPORT = 'Support',
}
