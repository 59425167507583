/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PbeFormCode {
    BOURSE_BROUILLON_REVE_DOCUMENTAIRE = 'BourseBrouillonReveDocumentaire',
    BOURSE_BROUILLON_REVE_EFE = 'BourseBrouillonReveEfe',
    BOURSE_BROUILLON_REVE_IMPACT = 'BourseBrouillonReveImpact',
    BOURSE_BROUILLON_REVE_JOURNALISME = 'BourseBrouillonReveJournalisme',
    BOURSE_BROUILLON_REVE_LITTERAIRE = 'BourseBrouillonReveLitteraire',
    BOURSE_BROUILLON_REVE_PHOTO_DESSIN = 'BourseBrouillonRevePhotoDessin',
    BOURSE_BROUILLON_REVE_RADIO_PODCAST = 'BourseBrouillonReveRadioPodcast',
    ETOILE_PRIX_AUDIVISUELS = 'EtoilePrixAudivisuels',
    ETOILE_PRIX_AUDIVISUELS_FORMULAIRE_SECOURS = 'EtoilePrixAudivisuelsFormulaireSecours',
    IDF_ACADEMY = 'IdfAcademy',
    PRIX_ALBERT_LONDRES_AUDIOVISUEL = 'PrixAlbertLondresAudiovisuel',
    PRIX_ALBERT_LONDRES_LIVRE = 'PrixAlbertLondresLivre',
    PRIX_ALBERT_LONDRES_PRESSE = 'PrixAlbertLondresPresse',
    PRIX_EMERGENCES = 'PrixEmergences',
    PRIX_OEUVRE_INSTITUTIONNELLE = 'PrixOeuvreInstitutionnelle',
    PRIX_ROGER_PIC = 'PrixRogerPic',
    SOUTIEN_FINANCIER_MANIFESTATION = 'SoutienFinancierManifestation',
    SOUTIEN_FINANCIER_STRUCTURE = 'SoutienFinancierStructure',
}
