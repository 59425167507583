/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PartnerType } from '../models/PartnerType';
import type { WorkFrontDto } from '../models/WorkFrontDto';
import type { WorkPartnerFrontDto } from '../models/WorkPartnerFrontDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WrittenWorkService {

    /**
     * @param requestBody 
     * @returns WorkFrontDto Success
     * @throws ApiError
     */
    public static completeWrittenWorkWrittenWork(
requestBody?: WorkFrontDto,
): CancelablePromise<WorkFrontDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/writtenwork/completewrittenwork',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param beneficiaryId 
     * @returns WorkFrontDto Success
     * @throws ApiError
     */
    public static getDraftWrittenBookWrittenWork(
beneficiaryId: number,
): CancelablePromise<WorkFrontDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/writtenwork/draftwrittenbook/{beneficiaryId}',
            path: {
                'beneficiaryId': beneficiaryId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param beneficiaryId 
     * @param workId 
     * @returns WorkFrontDto Success
     * @throws ApiError
     */
    public static getWrittenWorkWrittenWork(
beneficiaryId: number,
workId: number,
): CancelablePromise<WorkFrontDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/writtenwork/writtenwork/{beneficiaryId}/{workId}',
            path: {
                'beneficiaryId': beneficiaryId,
                'workId': workId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param partnerType 
     * @returns WorkPartnerFrontDto Success
     * @throws ApiError
     */
    public static getWrittenWorkPartnersWrittenWork(
partnerType: PartnerType,
): CancelablePromise<Array<WorkPartnerFrontDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/writtenwork/writtenworkpartners/{partnerType}',
            path: {
                'partnerType': partnerType,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns WorkFrontDto Success
     * @throws ApiError
     */
    public static saveWrittenWorkWrittenWork(
requestBody?: WorkFrontDto,
): CancelablePromise<WorkFrontDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/writtenwork/savewrittenwork',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server Error`,
            },
        });
    }

}
