/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PbeSessionFrontDto } from '../models/PbeSessionFrontDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PbeSessionService {

    /**
     * @param sessionId 
     * @returns PbeSessionFrontDto Success
     * @throws ApiError
     */
    public static getPbeSessionByIdPbeSession(
sessionId: number,
): CancelablePromise<PbeSessionFrontDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pbesession/{sessionId}',
            path: {
                'sessionId': sessionId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns PbeSessionFrontDto Success
     * @throws ApiError
     */
    public static getPbeSessionsAdvisoPbeSession(): CancelablePromise<Array<PbeSessionFrontDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/pbesession/getpbesessionsadviso',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param pbeFormCode 
     * @param getOnlyActiveSessions 
     * @returns PbeSessionFrontDto Success
     * @throws ApiError
     */
    public static getPbeSessionsPbeSession(
pbeFormCode: string,
getOnlyActiveSessions: boolean,
): CancelablePromise<Array<PbeSessionFrontDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pbesession/getpbesessions/{pbeFormCode}/{getOnlyActiveSessions}',
            path: {
                'pbeFormCode': pbeFormCode,
                'getOnlyActiveSessions': getOnlyActiveSessions,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

}
