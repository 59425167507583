/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ElectreNoticeSearchResult } from '../models/ElectreNoticeSearchResult';
import type { UserHiddenElectreNoticeDto } from '../models/UserHiddenElectreNoticeDto';
import type { WorkDetailBookFrontDto } from '../models/WorkDetailBookFrontDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ElectreService {

    /**
     * @param beneficiaryId 
     * @param eanOrIsbn 
     * @param author 
     * @param title 
     * @param publisher 
     * @param publicationYear 
     * @returns ElectreNoticeSearchResult Success
     * @throws ApiError
     */
    public static getAdvancedSearchResultElectre(
beneficiaryId?: number,
eanOrIsbn?: string,
author?: string,
title?: string,
publisher?: string,
publicationYear?: number,
): CancelablePromise<ElectreNoticeSearchResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/electre/getadvancedsearchresult',
            query: {
                'beneficiaryId': beneficiaryId,
                'eanOrIsbn': eanOrIsbn,
                'author': author,
                'title': title,
                'publisher': publisher,
                'publicationYear': publicationYear,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param ean 
     * @returns WorkDetailBookFrontDto Success
     * @throws ApiError
     */
    public static getNoticesByEanOrIsbnElectre(
ean?: string,
): CancelablePromise<Array<WorkDetailBookFrontDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/electre/getnoticesbyeanorisbn',
            query: {
                'ean': ean,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param beneficiaryId 
     * @returns WorkDetailBookFrontDto Success
     * @throws ApiError
     */
    public static getNoticesByElectreAuthorIdElectre(
beneficiaryId?: number,
): CancelablePromise<Array<WorkDetailBookFrontDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/electre/getnoticesbyelectreauthorid',
            query: {
                'beneficiaryId': beneficiaryId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param beneficiaryId 
     * @returns WorkDetailBookFrontDto Success
     * @throws ApiError
     */
    public static getNoticesWithUniqueAuthorsElectre(
beneficiaryId?: number,
): CancelablePromise<Array<WorkDetailBookFrontDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/electre/getnoticeswithuniqueauthorsasync',
            query: {
                'beneficiaryId': beneficiaryId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param electreNoticeId 
     * @param isHidden 
     * @returns UserHiddenElectreNoticeDto Success
     * @throws ApiError
     */
    public static setNoticeHiddenStateElectre(
electreNoticeId: string,
isHidden: boolean,
): CancelablePromise<UserHiddenElectreNoticeDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/electre/setnoticehiddenstate/{electreNoticeId}/{isHidden}',
            path: {
                'electreNoticeId': electreNoticeId,
                'isHidden': isHidden,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

}
