import {
  Configuration,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser"

export interface IMsalSettingsData {
  instance: string
  tenantId: string
  clientId: string
}

interface IMsalConfigInstanceData {
  msalSettings: IMsalSettingsData
  msalConfig: Configuration
  loginRequestScopes: Array<string>
  acquireTokenScopes: Array<string>
  publicClientApplication: PublicClientApplication
}

export class MsalConfig {
  private static _instanceData: IMsalConfigInstanceData | null = null

  private constructor() {}

  public static GetSettings = () => {
    if (MsalConfig._instanceData) return MsalConfig._instanceData.msalSettings

    throw console.error(
      'MsalConfig has not been initialized. Please use the "Setup" function to initialize before accessing.',
    )
  }

  public static GetConfiguration = () => {
    if (MsalConfig._instanceData) return MsalConfig._instanceData.msalConfig

    throw console.error(
      'MsalConfig has not been initialized. Please use the "Setup" function to initialize before accessing.',
    )
  }

  public static GetLoginRequestScopes = () => {
    if (MsalConfig._instanceData)
      return MsalConfig._instanceData.loginRequestScopes

    throw console.error(
      'MsalConfig has not been initialized. Please use the "Setup" function to initialize before accessing.',
    )
  }

  public static GetAcquireTokenScopes = () => {
    if (MsalConfig._instanceData)
      return MsalConfig._instanceData.acquireTokenScopes

    throw console.error(
      'MsalConfig has not been initialized. Please use the "Setup" function to initialize before accessing.',
    )
  }

  public static GetPublicClientApplication = () => {
    if (MsalConfig._instanceData)
      return MsalConfig._instanceData.publicClientApplication

    throw console.error(
      'MsalConfig has not been initialized. Please use the "Setup" function to initialize before accessing.',
    )
  }

  public static Setup = (msalJsonFileContent: unknown) => {
    if (!msalJsonFileContent)
      throw console.error(
        "MsalConfig.Setup | msalJsonFileContent is null or empty",
      )

    const object = JSON.parse(msalJsonFileContent as string)

    const msalSettings: IMsalSettingsData = object?.msal

    if (!msalSettings) throw console.error("MsalConfig.Setup | msal is missing")

    if (!msalSettings?.instance)
      throw console.error("MsalConfig.Setup | msal?.instance is missing")

    if (!msalSettings?.tenantId)
      throw console.error("MsalConfig.Setup | msal?.tenantId is missing")

    if (!msalSettings?.clientId)
      throw console.error("MsalConfig.Setup | msal?.clientId is missing")

    /**
     * For a full list of MSAL.js configuration parameters, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
     */
    const msalConfig: Configuration = {
      auth: {
        // App ID of your application. Can be found in your portal registration. Default: 	None. This parameter is required in order for MSAL to perform any actions.
        clientId: msalSettings.clientId,

        // URI of the tenant to authenticate and authorize with. Usually takes the form of https://{uri}/{tenantid} Default: https://login.microsoftonline.com/common
        authority: `${msalSettings.instance}/${msalSettings.tenantId}`,

        // An array of URIs that are known to be valid. Used in B2C scenarios. Default: "https://login.microsoftonline.com/common" Default: Empty array []
        knownAuthorities: [],

        // String in absolute or relative URI format. Default: Login request page (window.location.href of page which made auth request)
        redirectUri: "/admin",

        // cloudDiscoveryMetadata: "", //A string containing the cloud discovery response. Used in AAD scenarios. See performance.md for more info. Default: Empty string ""
        // postLogoutRedirectUri: "", //URI that is redirected to after a logout() call is made. Default: Login request page (window.location.href of page which made auth request)
        // navigateToLoginRequestUrl: true, //If true, will navigate back to the original request location before processing the authorization code response. If the redirectUri is the same as the original request location, this flag should be set to false. Default: true
        // clientCapabilities: [], //Array of capabilities to be added to all network requests as part of the xms_cc claims request. Default: []
        // protocolMode: "AAD", //Enum representing the protocol mode to use. If "AAD", will function on the OIDC-compliant AAD v2 endpoints; if "OIDC", will function on other OIDC-compliant endpoints. Default: "AAD"
      },

      cache: {
        // Location of token cache in browser. Default: "sessionStorage"
        cacheLocation: "sessionStorage", // This configures where your cache will be stored

        // If true, stores cache items in cookies as well as browser cache. Should be set to true for use cases using IE. Default: false
        // Set this to "true" if you are having issues on IE11 or Edge
        storeAuthStateInCookie: MsalConfig.isIE(),

        // If true and storeAuthStateInCookies is also enabled, MSAL adds the Secure flag to the browser cookie so it can only be sent over HTTPS. Default: false
        secureCookies: false,
      },

      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message)
                return

              case LogLevel.Info:
                console.info(message)
                return

              case LogLevel.Verbose:
                console.debug(message)
                return

              case LogLevel.Warning:
                console.warn(message)
            }
          },
        },
      },
    }

    const publicClientApplication = new PublicClientApplication(msalConfig)

    MsalConfig._instanceData = {
      msalSettings: msalSettings,
      msalConfig: msalConfig,
      loginRequestScopes: ["openid"],
      acquireTokenScopes: [`api://${msalSettings.clientId}/webapi`],
      publicClientApplication: publicClientApplication,
    }
  }

  private static isIE = () => {
    const ua = window.navigator.userAgent
    const msie = ua.indexOf("MSIE ") > -1
    const msie11 = ua.indexOf("Trident/") > -1

    // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
    // const isEdge = ua.indexOf("Edge/") > -1;

    return msie || msie11
  }
}
