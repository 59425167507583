/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum GenderType {
    DRAWING = 'Drawing',
    INFOGRAPHICS = 'Infographics',
    OTHER = 'Other',
    PHOTOGRAPHY = 'Photography',
}
