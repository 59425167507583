/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AssociatedPartnerType {
    ASSOCIATION = 'Association',
    BROADCASTER = 'Broadcaster',
    DISTRIBUTOR = 'Distributor',
    EDITOR = 'Editor',
    FESTIVAL = 'Festival',
    GALLERY = 'Gallery',
    LABORATORY = 'Laboratory',
    MUSEUM = 'Museum',
    OTHER = 'Other',
    PRODUCER = 'Producer',
    PROFESSIONAL_INSTITUTION = 'ProfessionalInstitution',
    SCHOOL = 'School',
    SPONSOR = 'Sponsor',
}
