/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ScamMembershipStatus {
    INPROGRESS_MEMBERSHIP = 'InprogressMembership',
    MEMBER = 'Member',
    NON_MEMBER = 'NonMember',
}
