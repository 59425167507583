import "./index.scss"

import {
  EventMessage,
  EventType,
  IPublicClientApplication,
} from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js"
import { ThemeProvider } from "@mui/material"
import { ErrorBoundary, LEVEL, Provider } from "@rollbar/react"
import axios from "axios"
import React from "react"
import ReactDOM from "react-dom"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"

import theme from "./core/theme"
import logger from "./helpers/logger"
import { MsalConfig } from "./MsalConfig"
import UserContextProvider from "./UserContextProvider"

const App = React.lazy(() => import("./App"))
const AdminApp = React.lazy(() => import("./AdminApp"))

const container = document.getElementById("root") as Element | DocumentFragment

const root = createRoot(container)

if (
  /* !window.location.pathname.toLowerCase().startsWith("/adminmco") && */
  window.location.pathname.toLowerCase().startsWith("/admin")
) {
  logger.init(true)
  const renderAdminApp = (msalInstance: IPublicClientApplication) => {
    ReactDOM.render(
      <AppInsightsErrorBoundary
        onError={() => (
          <h1>Erreur inattendue. Merci de contacter votre administrateur.</h1>
        )}
        appInsights={logger.getReactPlugin()}
      >
        <Provider instance={logger.getRollbar()}>
          <ErrorBoundary
            level={process.env.REACT_APP_ROLLBAR_MINIMAL_LOG_LEVEL as LEVEL}
          >
            <BrowserRouter basename="/">
              <React.Suspense fallback={<div></div>}>
                <MsalProvider instance={msalInstance}>
                  <ThemeProvider theme={theme}>
                    <UserContextProvider children={<AdminApp />} />
                  </ThemeProvider>
                </MsalProvider>
              </React.Suspense>
            </BrowserRouter>
          </ErrorBoundary>
        </Provider>
      </AppInsightsErrorBoundary>,
      document.getElementById("root"),
    )
  }

  const setupMsalBearerAxiosInterceptors = (
    msalInstance: IPublicClientApplication,
    genericErrorHandler: (error: unknown) => void,
  ) => {
    axios.interceptors.request.use(
      (config) => {
        const account = msalInstance.getActiveAccount()
        if (!account) {
          throw Error(
            "No active account! Verify a user has been signed in and setActiveAccount has been called.",
          )
        }

        return new Promise((resolve, reject) => {
          const acquireTokenRequest = {
            scopes: MsalConfig.GetAcquireTokenScopes(),
            account: account,
          }

          return msalInstance
            .acquireTokenSilent(acquireTokenRequest)
            .then((response) => {
              if (response?.accessToken) {
                if (config.headers)
                  config.headers.Authorization = `Bearer ${response.accessToken}`
                resolve(config)
              } else {
                reject(config)
              }
            })
        })
      },

      (error) => {
        return Promise.reject(error)
      },
    )

    // En cas de session expirée, l'utilisateur est redirigé vers la page d'authentification
    axios.interceptors.response.use(
      undefined,

      (error) => {
        if (error && error.response && error.response.status === 401) {
          msalInstance.logoutRedirect({ postLogoutRedirectUri: "/" })
        }

        if (!error.config?.__colas_lorie_disableGenericErrorHandler) {
          genericErrorHandler(error)
        }

        return Promise.reject(error)
      },
    )
  }

  const msalSetup = `{
    "msal": {
      "instance": "${process.env.REACT_APP_INSTANCE_ID}",
      "tenantId": "${process.env.REACT_APP_TENANT_ID}",
      "clientId": "${process.env.REACT_APP_CLIENT_ID}"
    }
  }`
  MsalConfig.Setup(msalSetup)

  const msalInstance = MsalConfig.GetPublicClientApplication()

  // NOTE technique : "MSAL.js for React TypeScript Sample"
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-react-samples/typescript-sample/src/index.tsx
  msalInstance.addEventCallback((event: EventMessage) => {
    switch (event.eventType) {
      case EventType.HANDLE_REDIRECT_END: {
        const accounts = msalInstance.getAllAccounts()
        if (accounts.length > 0) {
          msalInstance.setActiveAccount(accounts[0])

          setupMsalBearerAxiosInterceptors(msalInstance, () => {
            console.log("Erreur d'authentification")
          })

          renderAdminApp(msalInstance)
        }

        break
      }

      default:
        break
    }
  })

  renderAdminApp(msalInstance)
} else {
  logger.init(false)
  root.render(
    <AppInsightsErrorBoundary
      onError={() => (
        <h1>Erreur inattendue. Merci de contacter votre administrateur.</h1>
      )}
      appInsights={logger.getReactPlugin()}
    >
      <Provider instance={logger.getRollbar()}>
        <ErrorBoundary
          level={process.env.REACT_APP_ROLLBAR_MINIMAL_LOG_LEVEL as LEVEL}
        >
          <React.Suspense fallback={<div>Chargement...</div>}>
            <ThemeProvider theme={theme}>
              <UserContextProvider children={<App />} />
            </ThemeProvider>
          </React.Suspense>
        </ErrorBoundary>
      </Provider>
    </AppInsightsErrorBoundary>,
  )
}
