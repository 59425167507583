/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PbeFormType {
    BOURSE = 'Bourse',
    ETOILE = 'Etoile',
    PRIX_ALBERT_LONDRES = 'PrixAlbertLondres',
    PRIX_SCAM = 'PrixScam',
}
