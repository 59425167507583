/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AssociatedContactFunction {
    AUTHOR = 'Author',
    DIRECTOR = 'Director',
    DIRECTOR_AUTHOR = 'DirectorAuthor',
    EXISTING_WORK_AUTHOR = 'ExistingWorkAuthor',
    EXISTING_WORK_PUBLISHER = 'ExistingWorkPublisher',
    GRAPHIC_DESIGNER = 'GraphicDesigner',
    JOURNALISTE = 'Journaliste',
    OTHER = 'Other',
    PHOTOGRAPHER = 'Photographer',
    TRANSLATOR = 'Translator',
}
