/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApplicationFrontDto } from '../models/ApplicationFrontDto';
import type { PbeAdminApplicationModel } from '../models/PbeAdminApplicationModel';
import type { PbeDraftApplicationFrontDto } from '../models/PbeDraftApplicationFrontDto';
import type { PbeTokenFrontDto } from '../models/PbeTokenFrontDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PbeSagaService {

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static generatePebDeclarationPdfPbeSaga(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/pbesaga/generatepebdeclarationpdf',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static generatePebReperagesPdfPbeSaga(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/pbesaga/generatepebreperagespdf',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param pbeDraftApplicantId 
     * @returns PbeAdminApplicationModel Success
     * @throws ApiError
     */
    public static getAdminPbeApplicationsFollowupPbeSaga(
pbeDraftApplicantId?: string,
): CancelablePromise<Array<PbeAdminApplicationModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pbesaga/getadminpbeapplicationsfollowup',
            query: {
                'pbeDraftApplicantId': pbeDraftApplicantId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param pbeTokenId 
     * @returns PbeTokenFrontDto Success
     * @throws ApiError
     */
    public static invalidateUserTokenPbeSaga(
pbeTokenId: number,
): CancelablePromise<PbeTokenFrontDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/pbesaga/invalidateusertoken/{pbeTokenId}',
            path: {
                'pbeTokenId': pbeTokenId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param pbeDraftApplicantId 
     * @returns ApplicationFrontDto Success
     * @throws ApiError
     */
    public static removeDraftApplicationPbeSaga(
pbeDraftApplicantId?: string,
): CancelablePromise<ApplicationFrontDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/pbesaga/removedraftapplication',
            query: {
                'pbeDraftApplicantId': pbeDraftApplicantId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param pbeDraftApplicationId 
     * @returns PbeDraftApplicationFrontDto Success
     * @throws ApiError
     */
    public static restartApplicationPublicationPbeSaga(
pbeDraftApplicationId: string,
): CancelablePromise<PbeDraftApplicationFrontDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/pbesaga/restartapplicationpublication/{pbeDraftApplicationId}',
            path: {
                'pbeDraftApplicationId': pbeDraftApplicationId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param pbeDraftApplicantId 
     * @returns ApplicationFrontDto Success
     * @throws ApiError
     */
    public static startSagaPbeSaga(
pbeDraftApplicantId?: string,
): CancelablePromise<ApplicationFrontDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/pbesaga/startsaga',
            query: {
                'pbeDraftApplicantId': pbeDraftApplicantId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

}
