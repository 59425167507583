/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ApplicationWorkType {
    RESIDENCE_PROJECT = 'ResidenceProject',
    SERIES = 'Series',
    SINGLE = 'Single',
}
