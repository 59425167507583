/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DolExploitationType {
    BOOK = 'Book',
    PRESS = 'Press',
    TELEVISUAL = 'Televisual',
}
