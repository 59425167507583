/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ApplicationStatus {
    ABANDONED = 'Abandoned',
    ACTION_PENDING = 'ActionPending',
    DIRECT_REPRESENTATION_PROPOSAL_TO_JURY = 'DirectRepresentationProposalToJury',
    FILE_RECEIVED_VERIFICATION_IN_PROGRESS = 'FileReceivedVerificationInProgress',
    NOT_ELIGIBLE = 'NotEligible',
    NOT_PRE_SELECTED = 'NotPreSelected',
    NOT_SELECTED = 'NotSelected',
    PRE_SELECTED_UNDER_REVIEW_BY_JURY = 'PreSelectedUnderReviewByJury',
    REGISTRATION_TO_BE_COMPLETED = 'RegistrationToBeCompleted',
    SPONSORED_PROJECT = 'SponsoredProject',
    UNDER_REVIEW_FOR_PRE_SELECTION = 'UnderReviewForPreSelection',
    WINNING_PROJECT = 'WinningProject',
}
