/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PbeFormFrontDto } from '../models/PbeFormFrontDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PbeFormService {

    /**
     * @param formId 
     * @returns PbeFormFrontDto Success
     * @throws ApiError
     */
    public static getPbeFormByIdPbeForm(
formId: number,
): CancelablePromise<PbeFormFrontDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pbeform/{formId}',
            path: {
                'formId': formId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns PbeFormFrontDto Success
     * @throws ApiError
     */
    public static getPbeFormsPbeForm(): CancelablePromise<Array<PbeFormFrontDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pbeform',
            errors: {
                500: `Server Error`,
            },
        });
    }

}
